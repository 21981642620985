.SvcForm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    position: fixed;
    background-color: #323233;
  }
  .question{
    margin-bottom: 30px;
  }
  .row{
    display: flex; 
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 10px;
  }
.radio-option{
  font-size: 0.8rem;
}
.SvcForm input[type="radio"]{
  transform: scale(1.25);
}
.SvcForm form{
    background-color: #1E1E1E;
    color: #fff;
    width: 360px;
    height: 600px;
    border-radius: 15px;
    padding: 25px;
    direction: rtl;
    text-align: right;
    overflow-y: scroll;
}
  
  .SvcForm label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 0.85rem;
  }
  .SvcForm small{
    font-size: 12.5px;
    font-weight: normal;
    margin-bottom: 4px;
    color: #F47A1B;
  }
  
  .SvcForm input[type="text"],
  textarea,select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    background-color: #f8f9fa;
    color: #495057;
    font-size: 14px;
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
  }

  .SvcForm select.form-select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    background-color: #f8f9fa;
    color: #495057;
    font-size: 14px;
    width: 100%;
  }
  
  .SvcForm button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    background-color: #F47A1B;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    margin-top: 20px;
    font-family: 'Changa', sans-serif;
  }
  
  .SvcForm button:hover {
    transition: .3s all ease-in;
    transform: scale(1.1);
    background-color: #ce5900;
  }
  