.language-selector {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .language-selector select {
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f5f5f5;
    color: #333;
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
  }
  
  .language-selector select:hover {
    background-color: #e0e0e0;
    border-color: #bbb;
  }
  
  .language-selector select:focus {
    outline: none;
    border-color: #888;
  }