@import url(https://fonts.googleapis.com/css2?family=Changa:wght@300;400;500;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --bgc: #D8DCE5;
  --color: #1E1C3B;
  --bgHero: url(/static/media/cover.b65402c4.webp);
  --bgProfile: url(/static/media/logo.84207735.jpg);
  --borderColor: #9C0022;
  --gradient: linear-gradient(90deg, #1E1C3B 0.02%, #1E1C3B 100.02%);
  --font:'Changa', sans-serif;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #D8DCE5;
  background-color: var(--bgc);
  color: #1E1C3B;
  color: var(--color);
  font-family: 'Changa', sans-serif;
  font-family: var(--font);
}

.btn {
  height: 46px;
  width: 204px;
  border-radius: 5px;
  background: linear-gradient(90deg, #1E1C3B 0.02%, #1E1C3B 100.02%);
  background: var(--gradient);
  border-radius: 5px;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  color: #D8DCE5;
  color: var(--bgc);
  font-weight: 500;
}
.svc-btn{
  background: transparent;
  color: #1E1C3B;
  border: 2px solid #1E1C3B;
}
.svc-btn:hover{
  background: linear-gradient(90deg, #1E1C3B 0.02%, #1E1C3B 100.02%);
  background: var(--gradient);
  color: #fff;
  border: 0px solid #fff;
}
h1 {
  font-size: 35px;
  font-weight: 500;
}

p {
  font-size: 20px;
  font-weight: 300;
}
.Footer a{
  color: #9C0022;
  color: var(--borderColor);
}
.cover{
    position: relative;
    width: 95vw;
    height: 250px;
    top: 10px;
    background-image: var(--bgHero);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}
.profile{
    position: absolute;
    width: 163px;
    height: 163px;
    border-radius: 50%;
    top: 140px;
    background-image: var(--bgProfile);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #1E1C3B;
    background-size: 110%;
}
.profile::before {
    content: "";
    position: absolute;
    top: -7px;
    left: -7px;
    right: -7px;
    bottom: -7px;
    border-radius: 50%;
    border: 2px solid var(--borderColor);
  }
.SocialIcons{
    margin-top: 28px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 34px;
    gap: 34px;
    width: 100vw;
    direction: rtl;
    width: 80vw;
}
.socialIcon{
    cursor: pointer;
    transition: .25s all ease-out;
display: flex;
flex-direction: column;
}
.socialIcon:hover{
    transition: .3s all ease-in;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
span{
    margin-top: 10px;
    font-size: 13px;
}
.heading-actions{
    display: none !important;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: white;
    color: #2E2E2E;
    padding: 20px;
    width: 400px;
    height: 525px;
    overflow-y: scroll;
    max-width: 90%;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .modal.feedback{
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    direction: rtl;
    font-family: var(--font);
  }
  
.feedback-form {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    max-width: 400px;
    margin: auto;
    justify-content: center;
    align-items: center;
  }
  
  .rating-container p, .thank-you-message p {
    margin: 0;
    font-size: 16px;
    text-align: center;
  }
  
  .stars {
    display: flex;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
  }
  
  .star {
    cursor: pointer;
    font-size: 25px;
    color: #ccc; /* Default empty star color */
  }
  
  .star.filled {
    color: #f5d432; /* Filled star color */;
  }
  
  .star:hover, .star:hover ~ .star.filled {
    color: #ffc107; /* Hover star color */
  }
  
  .star-radio {
    display: none;
  }
  
  .feedback-textarea {
    width: 250px;
    height: 100px;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .submit-button, .copy-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #F6D433;
    color: #2E2E2E;
    cursor: pointer;
    font-size: 16px;
    font-family: var(--font);
  }
  
  .submit-button:hover, .copy-button:hover {
    background-color: #0056b3;
  }
  
  .google-link {
    display: inline-block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
    font-size: 16px;
  }
  
.google-buttons{
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
  /* align-items: center; */
}
.SvcForm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    position: fixed;
    background-color: #323233;
  }
  .question{
    margin-bottom: 30px;
  }
  .row{
    display: flex; 
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 10px;
  }
.radio-option{
  font-size: 0.8rem;
}
.SvcForm input[type="radio"]{
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}
.SvcForm form{
    background-color: #1E1E1E;
    color: #fff;
    width: 360px;
    height: 600px;
    border-radius: 15px;
    padding: 25px;
    direction: rtl;
    text-align: right;
    overflow-y: scroll;
}
  
  .SvcForm label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 0.85rem;
  }
  .SvcForm small{
    font-size: 12.5px;
    font-weight: normal;
    margin-bottom: 4px;
    color: #F47A1B;
  }
  
  .SvcForm input[type="text"],
  textarea,select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    background-color: #f8f9fa;
    color: #495057;
    font-size: 14px;
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
  }

  .SvcForm select.form-select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    background-color: #f8f9fa;
    color: #495057;
    font-size: 14px;
    width: 100%;
  }
  
  .SvcForm button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    background-color: #F47A1B;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    margin-top: 20px;
    font-family: 'Changa', sans-serif;
  }
  
  .SvcForm button:hover {
    transition: .3s all ease-in;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background-color: #ce5900;
  }
  
.language-selector {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .language-selector select {
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f5f5f5;
    color: #333;
    cursor: pointer;
    transition: background-color 0.2s ease, border-color 0.2s ease;
  }
  
  .language-selector select:hover {
    background-color: #e0e0e0;
    border-color: #bbb;
  }
  
  .language-selector select:focus {
    outline: none;
    border-color: #888;
  }
