.SocialIcons{
    margin-top: 28px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 34px;
    width: 100vw;
    direction: rtl;
    width: 80vw;
}
.socialIcon{
    cursor: pointer;
    transition: .25s all ease-out;
display: flex;
flex-direction: column;
}
.socialIcon:hover{
    transition: .3s all ease-in;
    transform: scale(1.1);
}
span{
    margin-top: 10px;
    font-size: 13px;
}
.heading-actions{
    display: none !important;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: #2E2E2E;
    padding: 20px;
    width: 400px;
    height: 525px;
    overflow-y: scroll;
    max-width: 90%;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .modal.feedback{
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    direction: rtl;
    font-family: var(--font);
  }
  